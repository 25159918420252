import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Buttons from "../components/buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image from "../../static/PackshotStill_MediumRoast.jpg"
import { navigate } from "gatsby-link"
const fair = () => (
  <Layout>
    <Seo title="Kahvisi on Löfbergs Medium Roast." image={image} />
    <div className="result" id="1">
      <h3>Olet empaattinen auttaja.</h3>{" "}
      <p>
        Kaverisi saavat olla onnellisia, että heillä on kaltaisesi ystävä. Olet
        luotettava kuin peruskallio, ja tukeesi voi luottaa aina, ajasta ja
        paikasta riippumatta. Olet epäitsekkyyden ruumiillistuma ja laitat aina
        läheisesi itsesi edelle. 
        <br />
        <br />
        Kahvisi on Löfbergs Medium Roast. Tasapainoinen, kukkaisa ja
        kaakaoinen. 
      </p>
      <StaticImage
        src="../assets/PackshotStill_MediumRoast.jpg"
        alt="Löfbergs Fair Dark Roast"
      />{" "}
      <Buttons
        quote={"Kahvini on Löfbergs Medium Roast."}
        url={typeof window !== "undefined" ? window.location.href + "?fb" : ""}
      />
    </div>
    {typeof window !== "undefined"
      ? window.location.href.includes("?fb") && navigate("/")
      : ""}
  </Layout>
)

export default fair
